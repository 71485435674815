<template>
  <div>
    <div class="calendarData flix-form-group">
      <div>
        <showAppointments />
      </div>
      <div>
        <autoConfirm />
      </div>
    </div>

    <div
      class="flix-form-group"
      v-if="$store.state.business.unsaved.type === 'eventpicker'"
    >
      <label style="display: block; margin-bottom: 10px"
        >{{ $t("time.lastMinute.title") }}
        <help>{{ $t("time.lastMinute.info") }}</help></label
      >
      <lastMinute />
    </div>

    <div
      class="flix-form-group"
      v-else-if="$store.state.business.unsaved.type === 'services'"
    >
      <label style="display: block; margin-bottom: 10px"
        >{{ $t("time.lastMinute.title") }}
        <help>{{ $t("time.lastMinute.info") }}</help></label
      >
      <div class="flix-form-group">
        <lastMinute />
      </div>
      <div class="flix-form-group">
        <timeAdvance />
      </div>
    </div>

    <div
      class="flix-form-group flix-mt-50"
      v-if="$store.state.business.unsaved.type === 'eventpicker'"
    >
      <webinar />
    </div>

    <div class="flix-form-group flix-mt-50">
      <bookingMethod />
      <div style="margin-left: 35px">
        <verifyTime />
      </div>
    </div>

    <div class="flix-mt-50">
      <assistentAddress />
    </div>

    <div class="flix-mt-50">
      <assistentContact />
    </div>

    <saveBtn v-if="checkData()" class="saveBtn" :callback="setSave" />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  extends: def,
  components: {
    lastMinute() {
      return import("@/components/business/settings/lastMinute.vue");
    },
    timeAdvance() {
      return import("@/components/business/settings/advance");
    },
    webinar() {
      return import("@/components/business/webinar/");
    },
    assistentAddress() {
      return import("@/components/business/settings/address");
    },
    assistentContact() {
      return import("@/components/business/settings/contact");
    },
    autoConfirm() {
      return import("@/components/business/settings/autoConfirm");
    },
    showAppointments() {
      return import("@/components/business/settings/showAppointments");
    },
    bookingMethod() {
      return import("@/components/business/settings/bookingMethod");
    },
    verifyTime() {
      return import("@/components/business/settings/verifyTime");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    nextStep() {
      this.$router.push({ name: "form" });
    }
  }
};
</script>
<style lang="sass" scoped></style>
